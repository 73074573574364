import Layout from "../components/layout/layout";
import WelcomeBanner from "../components/WelcomeBanner";

export default function Dashboard() {


  return (
    
    <Layout>
      <div className="min-h-screen">
      <WelcomeBanner />
      </div>
    </Layout>
  );
}
