import Login from "./pages/auth/login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./hooks/useAuth";
import Admin from "./pages/admin";

function App() {
  return (
    <BrowserRouter>
    <AuthContextProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard/*" index element={<Admin />} />
      </Routes>
      </AuthContextProvider>
    </BrowserRouter>

    
  );
}

export default App;
