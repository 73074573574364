import { Card, Form, Modal } from "antd";
import React, { useReducer, useEffect } from "react";
import { FaPencilAlt, FaTrash, FaUserLock, FaUserPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import apiClient from "../apiClient";
import Button from "../components/button";
import InputField from "../components/input_field";
import Layout from "../components/layout/layout";
import Table from "../components/table";
import { useAuthContext } from "../hooks/useAuth";
import Routes from "../routes";

export default function ProfileList() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  let [form] = Form.useForm();
  let auth = useAuthContext().auth;
  let hasAction = auth.hasAction;
  const [state, dispatch] = useReducer(reducer, {});
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (value) => {
        return (
          <div className="flex gap-4">
            {hasAction("can_edit_profile") && (
              <button
                className="text-yellow-600"
                onClick={() => {
                  form.setFieldsValue({ profileName: value.name });
                  dispatch({ selectedProfileId: value.id });
                }}
              >
                <FaPencilAlt />
              </button>
            )}

            {hasAction("can_add_actions") && (
              <Link
                to={`${Routes.Authorization.pathRoute}/${value.id}?name=${value.name}&tab=actions`}
              >
                <button className="text-green-600">
                  <FaUserLock />
                </button>
              </Link>
            )}
            {hasAction("can_add_users_to_profile") && (
              <Link
                to={`${Routes.Authorization.pathRoute}/${value.id}?name=${value.name}&tab=users`}
              >
                <button className="text-blue-600">
                  <FaUserPlus />
                </button>
              </Link>
            )}

            {hasAction("can_delete_profile") && (
              <button
                className="text-red-600"
                onClick={() => {
                  Modal.confirm({
                    title: `Are your sure you want to delete ${value.name}`,
                    onOk: () => deleteProfile(value.id),
                  });
                }}
              >
                <FaTrash />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const deleteProfile = async (userId) => {
    try {
      const res = await apiClient({
        method: "delete",
        url: `/profiles/${userId}`,
      });
      if (res.data.success) {
        fetchData();
        Modal.success({ title: "User successfully deleted!" });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  const fetchData = async () => {
    dispatch({ fetchingData: true });
    try {
      const res = await apiClient({ method: "get", url: "/profiles" });
      if (res.data.success) {
        let data = res.data.data.map((profile) => ({
          ...profile,
          action: { id: profile.id, name: profile.name },
        }));
        dispatch({ data, fetchingData: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingData: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addProfile = async (profile = {}) => {
    let data = { ...profile, id: state.selectedProfileId };
    dispatch({ addingProfile: true });
    try {
      const res = await apiClient({
        method: "post",
        url: "/profiles",
        data,
      });
      if (res.data.success) {
        form.resetFields();
        dispatch({ addingProfile: false, selectedProfileId: null });
        fetchData();
        Modal.success({
          title: `Profile Successfully ${
            state.selectedProfileId ? "Updated" : "Added"
          }`,
        });
      } else {
        Modal.error({ title: "Error", content: <p>An error occured!</p> });
      }
    } catch (error) {
      dispatch({ addingProfile: false });
      Modal.error({ title: "Error", content: <p>An error occured!</p> });
      console.log(error);
    }
  };

  return (
    <Layout>
      <div>
        <Card>
          <h2 className="text-xl flex gap-4 items-center">
            Create New Profile
          </h2>
          <Form layout="vertical" form={form} onFinish={addProfile}>
            <Form.Item
              label="Profile Name"
              name="profileName"
              rules={[{ required: true, message: "Profile Name is required" }]}
            >
              <InputField className="max-w-sm" />
            </Form.Item>
            <Form.Item>
              {hasAction("can_add_profile") && (
                <Button
                  className="w-max"
                  type="primary"
                  loading={state.addingProfile}
                  htmlType="submit"
                >
                  {state.selectedProfileId
                    ? `Update ${form.getFieldValue("profileName")}`
                    : "Add Profile"}
                </Button>
              )}
            </Form.Item>
          </Form>
        </Card>
        <Table
          columns={columns}
          dataSource={state.data || []}
          loading={state.fetchingData}
        />
      </div>
    </Layout>
  );
}
