import {
  Card,
  Checkbox,
  Modal,
  Tabs,
  Table as AntTable,
  Spin,
  Tag,
} from "antd";
import React, { useReducer, useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useParams, useSearchParams } from "react-router-dom";
import apiClient from "../apiClient";
import Button from "../components/button";
import Layout from "../components/layout/layout";
import Table from "../components/table";
import Routes from "../routes";

const TabPane = Tabs.TabPane;
export default function Authorization() {
  const [searchQueryParams, setSearchQueryParams] = useSearchParams();

  const handleChange = (tab) => {
    setSearchQueryParams({ name: searchQueryParams.get("name"), tab });
  };
  return (
    <Layout>
      <Link to={Routes.ProfileList.sidebar}>
        <h2 className="text-xl text-primary flex gap-4 items-center">
          <FaArrowLeft className="text-xl" />
          {searchQueryParams.get("name")}
        </h2>
      </Link>
      <br />

      <Tabs
        type="card"
        onChange={handleChange}
        activeKey={searchQueryParams.get("tab") || "actions"}
      >
        <TabPane tab="Actions" key="actions" animated>
          <Actions />
        </TabPane>
        <TabPane tab="Users" key="users" animated>
          <Users />
        </TabPane>
      </Tabs>
    </Layout>
  );
}

const Actions = () => {
  const params = useParams();
  const [searchQueryParams, _] = useSearchParams();
  let profileId = params.id;
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    dispatch({ gettingActions: true });
    try {
      const actions = await apiClient({
        method: "get",
        url: `/profileActions/${profileId}`,
      });
      if (actions.data.success) {
        let actionsData = actions.data.data;
        let profileActions = actionsData
          .filter((action) => action.has)
          .map((action) => action.id);
        dispatch({
          actions: actionsData,
          selectedActions: profileActions,
          gettingActions: false,
        });
      } else {
        Modal.error({ title: "Error", content: <p>An error occured!</p> });
      }
    } catch (error) {
      dispatch({ gettingActions: false });
      Modal.error({ title: "Error", content: <p>An error occured!</p> });
      console.log(error);
    }
  }

  function handleChange(selectedActions) {
    dispatch({ selectedActions });
  }

  async function handleSave() {
    let body = {
      profileId,
      actions: state.selectedActions,
    };
    dispatch({ loading: true });
    try {
      const res = await apiClient({
        method: "post",
        url: "/profileActions",
        data: body,
      });
      dispatch({ loading: false });
      if (res.data.success) {
        Modal.success({ title: "Saved" });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ loading: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  }

  return (
    <Card title={`Add Actions to ${searchQueryParams.get("name")}`}>
      {!state.gettingActions ? (
        <div>
          <h3 className="text-xl">Actions</h3>
          <Checkbox.Group
          className="!grid grid-cols-2  gap-4"
            onChange={handleChange}
            value={state.selectedActions || []}
            options={state.actions?.map((action) => ({
              label: action.name,
              value: action.id,
            }))}
          />
          <div className="mt-10">
            <Button
              onClick={handleSave}
              className="w-max"
              loading={state.loading}
              type="primary"
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className="w-full h-20 flex justify-center items-center">
          <Spin />
        </div>
      )}
    </Card>
  );
};

const Users = () => {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});
  const params = useParams();
  let profileId = params.id;
  const [searchQueryParams, _] = useSearchParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const fetchData = async () => {
    dispatch({ fetchingData: true });
    try {
      const res = await apiClient({ method: "get", url: "/users" });
      if (res.data.success) {
        const data = res.data.data.map((user) => ({
          ...user,
          key: user.userId,
        }));
        const selectedUsers = data
          .filter((user) => user.profile?.profileId === Number(params.id))
          .map((user) => user.userId);
        setSelectedRowKeys(selectedUsers);
        dispatch({ data, fetchingData: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingData: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      AntTable.SELECTION_ALL,
      AntTable.SELECTION_INVERT,

      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      AntTable.SELECTION_NONE,
    ],
  };

  const columns = [
    {
      title: "First name ",
      dataIndex: "firstName",
      key: "firstName",
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Current Profile",
      dataIndex: "profile",
      key: "profile",
      render(value) {
        let name = value?.name;
        return name ? <Tag color="blue">{name}</Tag> : "";
      },
    },
  ];

  const addUsersToProfile = async () => {
    let body = {
      profileId: Number(profileId),
      userIds: selectedRowKeys,
    };
    dispatch({ loading: true });
    try {
      const res = await apiClient({
        method: "post",
        url: "/profiles/user",
        data: body,
      });
      dispatch({ loading: false });
      if (res.data.success) {
        fetchData()
        Modal.success({ title: "Saved" });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ loading: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  return (
    <Card title={`Add Users to ${searchQueryParams.get("name")}`}>
      <Table
        columns={columns}
        loading={state.fetchingData}
        rowSelection={rowSelection}
        dataSource={state.data}
        title="Users"
        actions={[
          <Button
            loading={state.loading}
            onClick={addUsersToProfile}
            type="primary"
          >
            Save
          </Button>,
        ]}
      />
    </Card>
  );
};
