import { Table as AntTable } from "antd";
import propTypes from "prop-types";
import { object } from "prop-types";
import React from "react";

function Table({ columns = [], dataSource = [], title, actions = [], ...rest }) {
  return (
    <div className="overflow-x-auto">
      <div className="p-2 flex justify-between items-center">
        <h1 className="text-xl text-black">{title}</h1>
        <div className="flex items-center flex-wrap gap-4">{actions.map((action, i) => <div key={i}>{action}</div>)}</div>
      </div>
      <AntTable
        className="rounded"
        expandable
        dataSource={dataSource}
        columns={columns}
        
        {...rest}
      />
    </div>
  );
}

Table.propTypes = {
  columns: propTypes.arrayOf(object),
  dataSource: propTypes.arrayOf(object),
};

export default Table;
