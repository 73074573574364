import { Modal } from "antd";
import classNames from "classnames";
import React, { useReducer, useEffect } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import apiClient from "../apiClient";
import Layout from "../components/layout/layout";
import Table from "../components/table";
import { useAuthContext } from "../hooks/useAuth";
import Routes from "../routes";

export default function Appointments() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});
  let auth = useAuthContext().auth;
  let hasAction = auth.hasAction;
  const columns = [
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (client) => {
        return (
          <Link to={`${Routes.ConsultClient.pathRoute}/${client.userId}`}>
            <a>
              {client.firstName} {client.lastName}
            </a>
          </Link>
        );
      },
    },
    {
      title: "Appointment Name",
      dataIndex: "appointmentName",
      key: "appointmentName",
    },
    {
      title: "Appointment Description",
      dataIndex: "appointmentDescription",
      key: "appointmentDescription",
      render(value) {
        return <div className="max-w-sm truncate">{value}</div>;
      },
    },

    {
      title: "Created Date",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
    },
    {
      title: "Status",
      dataIndex: "appointmentDone",
      key: "appointmentDone",
      render(value) {
        let cn = classNames({
          "p-1 text-xs text-center text-white w-max": true,
          "bg-green-500": value,
          "bg-yellow-500": !value,
        });
        return <div className={cn}>{value ? "Terminated" : "Pending"}</div>;
      },
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: ({ appointmentId }) => {
        return (
          <div className="flex justify-center space-x-4">
            <Link
              className="text-green-500"
              to={`${Routes.ConsultAppointment.pathRoute}/${appointmentId}`}
            >
              <FaPlus />
            </Link>
            {hasAction("can_consult_appointment") && (
              <Link
                className="text-yellow-500"
                to={`${Routes.AppointmentDetails.pathRoute}/${appointmentId}`}
              >
                <FaSearch />
              </Link>
            )}
          </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    dispatch({ fetchingData: true });
    try {
      const res = await apiClient({ method: "get", url: "/appointment" });
      if (res.data.success) {
        const data = res.data.data.map((val) => ({ ...val, action: val }));
        dispatch({ data, fetchingData: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingData: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <div>
        <Table
          columns={columns}
          dataSource={state.data || []}
          title="Appointments"
          loading={state.fetchingData}
        />
      </div>
    </Layout>
  );
}
