import {Button as AntButton} from "antd";
import classNames from "classnames";
import propTypes from "prop-types";

function Button(props) {
    const cn = classNames({
        [props.className]: true,
        "w-full": true,
        'rounded': true
        
    });
    return <AntButton size="large"  {...props} className={cn} />
}

Button.propTypes = {
    className: propTypes.any,
    onClick: propTypes.any,
}

export default Button;