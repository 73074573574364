import React, { useEffect, useReducer } from "react";
import Layout from "../components/layout/layout";
import { useParams } from "react-router-dom";
import apiClient from "../apiClient";
import { Modal, Tabs } from "antd";

export default function ConsultClient() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});

  const params = useParams();

  const fetchData = async () => {
    const res = await apiClient({
      method: "get",
      url: `/clients/${params.id}`,
    });
    if (res.data.success) {
      const data = res.data.data;
      dispatch({
        data,
        additionalInfo: JSON.parse(res.data.additionalInfo ?? "[]"),
      });
    } else {
      Modal.error({ title: "Error", content: <p>An error occured!</p> });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  let infoData = state.data ?? {};
  let educationData = infoData.education ?? {};
  let questionInfo = JSON.parse(infoData.info ?? '[]')
  return (
    <Layout>
      <h2 className="text-xl font-bold">Client Information</h2>
      <Tabs>
        <Tabs.TabPane tab="General Information" key="item-1">
          <div className="bg-white p-4 space-y-2">
            <Info name="First Name" value={infoData.firstName} />
            <Info name="Last Name" value={infoData.lastName} />
            <Info name="Email" value={infoData.emailAddress} />
            <Info name="Phone" value={infoData.phoneNumber} />
            <Info
              name="Gender"
              value={
                infoData.gender === "F"
                  ? "Female"
                  : infoData.gender === "M"
                  ? "Male"
                  : "Not Set"
              }
            />
            <Info name="Date Of Birth" value={infoData.dateOfBirth} />
            <Info name="Address" value={infoData.address} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Educational Information" key="item-2">
          <div className="bg-white p-4 space-y-2">
            <Info name="Certificate" value={educationData.certificate} />
            <Info name="Year" value={educationData.year} />
            <Info name="School" value={educationData.school} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Additional Information" key="item-3">
          <div className="bg-white p-4 space-y-2">
            {state.additionalInfo?.map((q, i) => {
              return <Info name={q} key={i} value={questionInfo[`Q${i}`]}  />;
            })}
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Layout>
  );
}

const Info = ({ name, value = "NOT SET" }) => {
  return (
    <div className="flex items-center gap-4">
      <h6 className="leading-[10px]">{name}: </h6>
      <h6 className="font-bold leading-tight text-primary">{value}</h6>
    </div>
  );
};
