import { Card, Form, Modal } from "antd";
import React, { useReducer, useEffect } from "react";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import apiClient from "../apiClient";
import Button from "../components/button";
import InputField from "../components/input_field";
import Layout from "../components/layout/layout";
import Table from "../components/table";
import { useAuthContext } from "../hooks/useAuth";

export default function ProfileList() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  let [form] = Form.useForm();
  const [state, dispatch] = useReducer(reducer, {});
  let auth = useAuthContext().auth;
  let hasAction = auth.hasAction;
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Name",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Category Key",
      dataIndex: "categoryKey",
      key: "categoryKey",
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (value) => {
        return (
          <div className="flex gap-4">
            {hasAction("can_edit_category") && (
              <button
                className="text-yellow-600"
                onClick={() => {
                  form.setFieldsValue({ categoryName: value.categoryName });
                  dispatch({ selectedCategoryId: value.categoryId });
                }}
              >
                <FaPencilAlt />
              </button>
            )}
            {hasAction("can_delete_category") && (
              <button
                className="text-red-600"
                onClick={() => {
                  Modal.confirm({
                    title: `Are your sure you want to delete ${value.categoryName}`,
                    onOk: () => deleteCategory(value.categoryId),
                  });
                }}
              >
                <FaTrash />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const deleteCategory = async (categoryId) => {
    try {
      const res = await apiClient({
        method: "delete",
        url: `/category/${categoryId}`,
      });
      if (res.data.success) {
        fetchData();
        Modal.success({ title: "Category successfully deleted!" });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  const fetchData = async () => {
    dispatch({ fetchingData: true });
    try {
      const res = await apiClient({ method: "get", url: "/category" });
      if (res.data.success) {
        let data = res.data.data.map((category) => ({
          ...category,
          action: category,
        }));
        dispatch({ data, fetchingData: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingData: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addCategory = async (category = {}) => {
    let data = { ...category, categoryId: state.selectedCategoryId };
    dispatch({ addingCategory: true });
    try {
      const res = await apiClient({
        method: "post",
        url: "/category",
        data,
      });
      if (res.data.success) {
        form.resetFields();
        dispatch({ addingCategory: false, selectedCategoryId: null });
        fetchData();
        Modal.success({
          title: `Category Successfully ${
            state.selectedCategoryId ? "Updated" : "Added"
          }`,
        });
      } else {
        Modal.error({ title: "Error", content: <p>An error occured!</p> });
      }
    } catch (error) {
      dispatch({ addingCategory: false });
      Modal.error({ title: "Error", content: <p>An error occured!</p> });
      console.log(error);
    }
  };

  return (
    <Layout>
      <div>
        <Card>
          <h2 className="text-xl flex gap-4 items-center">
            Create New Category
          </h2>
          <Form layout="vertical" form={form} onFinish={addCategory}>
            <Form.Item
              label="Category Name"
              name="categoryName"
              rules={[{ required: true, message: "Category Name is required" }]}
            >
              <InputField className="max-w-sm" />
            </Form.Item>
            <Form.Item>
              {hasAction("can_add_category") && (
                <Button
                  className="w-max"
                  type="primary"
                  loading={state.addingCategory}
                  htmlType="submit"
                >
                  {state.selectedCategoryId
                    ? `Update ${form.getFieldValue("categoryName")}`
                    : "Add Category"}
                </Button>
              )}
            </Form.Item>
          </Form>
        </Card>
        <Table
          columns={columns}
          dataSource={state.data || []}
          loading={state.fetchingData}
        />
      </div>
    </Layout>
  );
}
