import { useReducer } from "react";
import { createContext, useContext } from "react";
import apiClient from "../../apiClient";

function loginClient(user) {
  return apiClient({
    method: "post",
    url: "/login",
    data: user,
  });
}

const authContext = createContext();

export function AuthContextProvider({ children }) {
  const context = {
    auth: useAuth(),
  };
  return (
    <authContext.Provider value={context}>{children}</authContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(authContext);
}

function useAuth() {
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("name");
  const id = localStorage.getItem("userId");
  const actions = localStorage.getItem("actions") || "[]";
  let defaultState = {};
  try {
    defaultState = {
      user: { token, name, id: Number(id), actions: JSON.parse(actions) },
      isAuthenticated: true,
      loading: false,
      error: null,
    };
  } catch {
    defaultState = {
      user: { token: null, name: null, id: null, actions: [] },
      isAuthenticated: false,
      loading: false,
      error: null,
    };
  }
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [auth, setAuth] = useReducer(reducer, defaultState);

  const loginUser = async (user) => {
    setAuth({ loading: true });
    loginClient(user)
      .then((response) => {
        setAuth({ loading: false });
        if (response.status === 200 && response.data.success) {
          const {
            firstName,
            lastName,
            token,
            id,
            actions = [],
          } = response.data.data;
          const name = firstName + " " + lastName;
          localStorage.setItem("token", token);
          localStorage.setItem("name", name);
          localStorage.setItem("userId", id);
          localStorage.setItem("actions", JSON.stringify(actions));
          setAuth({
            error: null,
            user: { name, token, id: Number(id) },
            isAuthenticated: true,
          });
          window.location.replace("/dashboard/overview");
        } else {
          setAuth({
            error: { message: response.data.message },
            isAuthenticated: false,
          });
        }
      })
      .catch(() => {
        setAuth({ loading: false });
      });
  };

  const logoutUser = () => {
    localStorage.clear();
    setAuth({ user: {}, isAuthenticated: false, error: null });
    window.location.replace("/");
  };

  const hasAction = (action) => {
    let actions = auth.user.actions || [];
    return actions.includes(action);
  };
  return { ...auth, loginUser, logoutUser, hasAction };
}
