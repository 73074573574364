import { Input, InputNumber} from "antd";
import TextArea from "antd/lib/input/TextArea";
import classNames from "classnames";

export default function InputField (props) {
    const cn = classNames({
        [props.className]: true,
        "w-full": true,
        'rounded': true
        
    });
    return <Input className={cn}  size="large" {...props} />
}

InputField.Password = (props) => {
    const cn = classNames({
        [props.className]: true,
        "w-full": true,
        'rounded': true
        
    });
    return <Input.Password className={cn} size="large" {...props} />
}
InputField.Number = (props) => {
    // const cn = classNames({
    //     [props.className]: true,
    //     "w-full": true,
    //     'rounded': true
        
    // });
    return <InputNumber className="w-full" size="large" {...props} />
}

InputField.TextArea = (props) => {
    return <TextArea className="w-full" size="large" {...props} />
}