import React from "react";
import { Navigate, Route, Routes as Switch } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuth";
import Routes from "../routes";

export default function Admin() {
  const { user } = useAuthContext().auth;
  if (!user.token) {
    return <Navigate to="/" replace />;
  }
  return (
    <Switch>
      {Object.values(Routes).map((route, i) => {
        let Component = route.component;
        return (
          <Route key={i} index element={<Component />} path={route.path} />
        );
      })}
    </Switch>
  );
}
