import { Form, Modal, Select, Tag } from "antd";
import classNames from "classnames";
import React, { useReducer, useEffect, useState } from "react";
import { FaEdit, FaLock, FaTrash, FaUnlock } from "react-icons/fa";
import { MdRestartAlt } from "react-icons/md";
import apiClient from "../apiClient";
import Button from "../components/button";
import InputField from "../components/input_field";
import Layout from "../components/layout/layout";
import Table from "../components/table";
import { useAuthContext } from "../hooks/useAuth";

export default function Users() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});
  let auth = useAuthContext().auth;
  let hasAction = auth.hasAction;
  const columns = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Profile",
      dataIndex: "profile",
      key: "profile",
      render(value) {
        let name = value?.name;
        return name ? <Tag color="blue">{name}</Tag> : "";
      },
    },
    {
      title: "First name ",
      dataIndex: "firstName",
      key: "firstName",
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render(value) {
        let cn = classNames({
          "p-1 text-xs text-center text-white w-max": true,
          "bg-green-500": value,
          "bg-red-500": !value,
        });
        return <div className={cn}>{value ? "Activated" : "Disactivated"}</div>;
      },
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (value) => {
        let isMyAccount = value.userId === auth.user.id;
        let text = value.active ? "Activated" : "Disactivated";
        return (
          <div className="flex gap-4">
            {!isMyAccount && (
              <button
                className="text-yellow-600 text-lg"
                onClick={() => {
                  Modal.confirm({
                    title: `Are your sure you want to ${text} ${value.firstName} ${value.lastName}`,
                    onOk: () => disactivatedUser(value.userId),
                  });
                }}
              >
                {value.active ? <FaLock /> : <FaUnlock />}
              </button>
            )}
            {(!isMyAccount && hasAction("can_delete_user")) && (
              <button
                className="text-red-600 text-base"
                onClick={() => {
                  Modal.confirm({
                    title: `Are your sure you want to delete ${value.firstName} ${value.lastName}`,
                    onOk: () => deleteUser(value.userId),
                  });
                }}
              >
                <FaTrash />
              </button>
            )}

            {isMyAccount && (
              <button
                onClick={() => {
                  dispatch({ addModal: true, currentUser: value });
                }}
                className="text-blue-600"
              >
                <FaEdit />
              </button>
            )}
            {(hasAction("can_reset_user") && !isMyAccount) && (
              <button
                onClick={() => {
                  Modal.confirm({
                    title: `Are your sure you want to reset ${value.firstName} ${value.lastName}`,
                    onOk: () => resetUser(value.userId),
                  });
                }}
                className="text-green-600 text-xl"
              >
                <MdRestartAlt />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const deleteUser = async (userId) => {
    try {
      const res = await apiClient({
        method: "delete",
        url: `/users/${userId}`,
      });
      if (res.data.success) {
        fetchData();
        Modal.success({
          title: "User successfully deleted!",
        });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };
  const resetUser = async (userId) => {
    try {
      const res = await apiClient({
        method: "get",
        url: `/users/reset/${userId}`,
      });
      if (res.data.success) {
        fetchData();
        Modal.success({
          title: "User successfully resetted!",
        });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };



  const disactivatedUser = async (userId) => {
    try {
      const res = await apiClient({
        method: "put",
        url: `/users/disactivate/${userId}`,
      });
      if (res.data.success) {
        fetchData();
        Modal.success({ title: "Successfull" });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  const fetchData = async () => {
    dispatch({ fetchingData: true });
    try {
      const res = await apiClient({ method: "get", url: "/users" });
      const profiles = await apiClient({ method: "get", url: "/profiles" });
      if (res.data.success) {
        const data = res.data.data.map((val) => ({ ...val, action: val }));
        dispatch({ data, profiles: profiles.data.data, fetchingData: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingData: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function toggleModal() {
    dispatch({ addModal: !state.addModal, currentUser: null });
  }

  const createUser = (user) => {
    dispatch({ creating: true });
    apiClient({
      method: state.currentUser ? "patch" : "post",
      url: "/users",
      data: { ...user, userId: state.currentUser?.userId || null },
    })
      .then((res) => {
        if (res.data.success) {
          fetchData();
          dispatch({ currentUser: null });
          Modal.success({
            title: res.data.message,
            content: <p>User successfully created!</p>,
          });
        } else {
          Modal.error({
            title: res.data.message,
            content: <p>An error occured!</p>,
          });
        }

        toggleModal();
        dispatch({ creating: false });
      })
      .catch(() => {
        Modal.error({ title: "An error occured!" });
        toggleModal();
        dispatch({ creating: false });
      });
  };

  return (
    <Layout>
      <div>
        <Table
          columns={columns}
          dataSource={state.data || []}
          title="Users"
          loading={state.fetchingData}
          actions={[
            <>           
            { hasAction("can_add_user") && <Button type="primary" onClick={toggleModal}>
              Add User
            </Button>}
            </>
          ]}
        />
      </div>

      {state.addModal && (
        <User
          currentUser={state.currentUser}
          createUser={createUser}
          profiles={state.profiles}
          toggleModal={toggleModal}
          addModal={state.addModal}
          creating={state.creating}
        />
      )}
    </Layout>
  );
}

const User = ({
  createUser,
  currentUser,
  toggleModal,
  addModal,
  creating,
  profiles = [],
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  console.log(currentUser);
  useEffect(() => {
    setData(currentUser);

    if (currentUser) {
      form.resetFields();
    }
  });
  return (
    <Modal
      title="Add User"
      width={768}
      destroyOnClose
      onCancel={toggleModal}
      visible={addModal}
      footer={
        <div className="flex space-x-2">
          <Button onClick={toggleModal}>Cancel</Button>
          <Button type="primary" onClick={form.submit} loading={creating}>
            {currentUser ? "Update User" : "Add user"}
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={createUser}
        initialValues={data}
      >
        <div className="grid md:grid-cols-2 gap-x-4 w-full min-h-full">
          <Form.Item
            label="First name"
            id="firstName"
            name="firstName"
            rules={[{ required: true, message: "First name is required" }]}
          >
            <InputField />
          </Form.Item>
          <Form.Item
            label="Last Name"
            id="lastName"
            name="lastName"
            rules={[{ required: true, message: "Last name is required" }]}
          >
            <InputField />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            id="phoneNumber"
            name="phoneNumber"
            rules={[
              { required: true, message: "Phone number is required" },
              { min: 9 },
            ]}
          >
            <InputField />
          </Form.Item>
          <Form.Item
            label="Email Address"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email" },
            ]}
            id="emailAddress"
            name="emailAddress"
          >
            <InputField />
          </Form.Item>
          {currentUser && (
            <Form.Item label="Password" id="password" name="password">
              <InputField.Password />
            </Form.Item>
          )}
          <Form.Item
            label="Profile"
            rules={[
              {
                required: !currentUser,
                message: "Profile is required",
              },
            ]}
            id="profile"
            name="profile"
          >
            <Select disabled={currentUser} size="large">
              <Select.Option>Select Profile</Select.Option>
              {profiles.map((profile, i) => {
                return (
                  <Select.Option value={profile.id} key={i}>
                    {profile.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
