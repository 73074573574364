import AppointmentDetails from "../pages/AppointmentDetails";
import Appointments from "../pages/Appointments";
import Authorization from "../pages/Authorization";
import Category from "../pages/Category";
import Clients from "../pages/clients";
import ConfigPage from "../pages/ConfigPage";
import ConsultAppointment from "../pages/ConsultAppointment";
import ConsultClient from "../pages/ConsultClient";
import Dashboard from "../pages/dashboard";
import ProfileList from "../pages/ProfileList";
import Users from "../pages/users";

const Routes = {
  Dashboard: {
    path: "/overview",
    sidebar: "/dashboard/overview",
    component: Dashboard,
  },
  ConfigPage: {
    path: "/config",
    sidebar: "/dashboard/config",
    component: ConfigPage,
  },
  Users: {
    path: "/users",
    sidebar: "/dashboard/users",
    component: Users,
  },
  Clients: {
    path: "/clients",
    sidebar: "/dashboard/clients",
    component: Clients,
  },
  ConsultClient: {
    path: "/clients/:id",
    // sidebar: "/dashboard/clients",
    pathRoute: "/dashboard/clients",
    component: ConsultClient,
  },
  Authorization: {
    pathRoute: "/dashboard/authorization",
    path: "/authorization/:id",
    sidebar: "/dashboard/authorization",
    component: Authorization,
  },
  ProfileList: {
    path: "/profiles",
    sidebar: "/dashboard/profiles",
    component: ProfileList,
  },
  Category: {
    path: "/category",
    sidebar: "/dashboard/category",
    component: Category,
  },
  Appointments: {
    path: "/appointments",
    sidebar: "/dashboard/appointments",
    component: Appointments,
  },
  AppointmentDetails: {
    path: "/appointments/:appointmentId",
    pathRoute: "/dashboard/appointments",
    // sidebar: "/dashboard/appointments",
    component: AppointmentDetails,
  },
  ConsultAppointment: {
    path: "/appointments/consult/:appointmentId",
    pathRoute: "/dashboard/appointments/consult",
    // sidebar: "/dashboard/appointments",
    component: ConsultAppointment,
  },
};

export default Routes;
