import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FiActivity, FiLock, FiTool, FiUser, FiUsers } from "react-icons/fi";
import Routes from "../../routes";
import { useAuthContext } from "../../hooks/useAuth";
import { FaBook, FaColumns } from "react-icons/fa";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;
  const { hasAction } = useAuthContext().auth;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const routes = [
    {
      name: "Dashboard",
      icon: () => <FiActivity className="text-3xl" />,
      path: Routes.Dashboard.sidebar,
    },
    {
      name: "Config",
      icon: () => <FiTool className="text-3xl" />,
      path: Routes.ConfigPage.sidebar,
      show: hasAction("can_configure"),
    },
    {
      name: "Category",
      icon: () => <FaColumns className="text-3xl" />,
      path: Routes.Category.sidebar,
      show: hasAction("can_view_category"),
    },
    {
      name: "Users",
      icon: () => <FiUser className="text-3xl" />,
      path: Routes.Users.sidebar,
      show: hasAction("can_view_user"),
    },
    {
      name: "Authorization",
      icon: () => <FiLock className="text-3xl" />,
      path: Routes.ProfileList.sidebar,
      show: hasAction("can_view_profile"),
    },
    {
      name: "Clients",
      icon: () => <FiUsers className="text-3xl" />,
      path: Routes.Clients.sidebar,
      show: hasAction("can_view_clients"),
    },
    {
      name: "Appointments",
      icon: () => <FaBook className="text-3xl" />,
      path: Routes.Appointments.sidebar,
      // show: hasAction("can_view_appointment"),
    },
  ];



  return (
    <div>
{ sidebarOpen &&     <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col  z-40 left-0 top-0 static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-20 lg:w-20 2xl:!w-64 shrink-0 bg-[#2b2b2b] p-4 transition-all duration-200 ease-in-out`}
      >
        <div className="flex justify-center mb-10 2xl:pr-3">
          <NavLink exact to="/" className="text-white font-bold">
            GIP
          </NavLink>
        </div>

        <div className="space-y-8">
          <div>
            <h3 className="text-xs uppercase text-gray-500 2xl:pl-3">
              <span className="text-primary ">
                Pages
              </span>
            </h3>
            <ul className="mt-3 space-y-2">
              {routes.map((route, i) => {
                const active = pathname.includes(route.path);
                const Icon = route.icon;
                if (route.show === false) {
                  return;
                }
                return (
                  <li
                    key={i}
                    className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                      active && "bg-primary"
                    }`}
                  >
                    <NavLink
                      exact
                      to={route.path}
                      className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                        active && "hover:text-gray-200"
                      }`}
                    >
                      <div className="flex items-center">
                        <Icon active={active} />
                        <span className="text-sm font-medium hidden 2xl:block ml-3 duration-200">
                          {route.name}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-gray-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-gray-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>}
    </div>
  );
}

export default Sidebar;
