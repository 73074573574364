import { Form, Modal, Table, Timeline } from "antd";
import moment from "moment";
import React, { useReducer, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import apiClient from "../apiClient";
import Button from "../components/button";
import InputField from "../components/input_field";
import Layout from "../components/layout/layout";
import { useAuthContext } from "../hooks/useAuth";
import Routes from "../routes";

export default function ConsultAppointment() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});
  const [form] = Form.useForm();
  const params = useParams();
  const appointmentId = params.appointmentId;
  let auth = useAuthContext().auth;
  let hasAction = auth.hasAction;

  const fetchData = async () => {
    dispatch({ fetchingData: true });
    try {
      const res = await apiClient({
        method: "get",
        url: `/appointment/${appointmentId}`,
      });
      if (res.data.success) {
        const data = res.data.data;
        const agents = res.data.agents;
        let exceptIds = agents.map((user) => user.userId) || [];
        dispatch({ data, agents, exceptIds, fetchingData: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingData: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  const getProfile = async () => {
    dispatch({ fetchingProfile: true });
    try {
      const res = await apiClient({
        method: "get",
        url: "/profiles",
      });
      if (res.data.success) {
        const profiles = res.data.data.map((profile) => ({
          label: profile.name,
          value: profile.id,
        }));
        dispatch({ profiles, fetchingProfile: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingProfile: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  const columns = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Profile",
      dataIndex: "profile",
      key: "profile",
      render(profile = {}) {
        let name = profile.profileName;
        return name ? (
          <div className="w-max py-1 px-2 border text-primary border-primary">
            {name}
          </div>
        ) : (
          ""
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
    getProfile();
  }, []);

  async function createMeeting(meeting = {}) {
    try {
      dispatch({ creatingMeeting: true });

      let res = await apiClient({
        url: "/activity",
        method: "post",
        data: { ...meeting, type: "CREATE_MEETING", appointmentId },
      });

      if (res.data.success) {
        fetchData();
        Modal.success({ title: "Meeting Created", onOk: toggleModal });
        dispatch({ creatingMeeting: false });
      } else {
        Modal.error({
          title: res.data.message || "An error occured",
          onOk: toggleModal,
        });
        dispatch({ creatingMeeting: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ creatingMeeting: false });
      Modal.error({ title: "An error occured", onOk: toggleModal });
    }
  }

  async function closeAppoinment() {
    dispatch({ closing: true });
    try {
      let res = await apiClient({
        method: "delete",
        url: `/appointment/${appointmentId}`,
      });
      dispatch({ closing: false });
      if (res.data.success) {
        fetchData();
        Modal.success({ title: "Appointment closed successfully!" });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      console.log(error);
      dispatch({ closing: false });
      Modal.error({ title: "An error occured!" });
    }
  }
  let data = state.data || {};

  const toggleModal = () => {
    dispatch({ showModal: !state.showModal });
  };
  return (
    <Layout>
      <div className="space-y-4">
        {!data.appointmentDone && (
          <div className="flex space-x-4 justify-end">
            <Button
              loading={state.closing}
              className="w-max"
              type="primary"
              danger
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure you want to close this appointment ? ",
                  onOk: closeAppoinment,
                });
              }}
            >
              Close Appointment
            </Button>
            <Button className="w-max" type="primary" onClick={toggleModal}>
              Create a meeting
            </Button>
          </div>
        )}

        <div className="bg-white">
          <h2 className="font-bold bg-primary p-2 text-white h-10 m-0">
            Sender Details
          </h2>
          <div className="p-4">
            <h6 className="flex gap-4 items-center">
              Name:{" "}
              <span className="text-primary">
                {data.client?.firstName} {data.client?.lastName}
              </span>
            </h6>
            <h6 className="flex gap-4 items-center">
              Phone:{" "}
              <span className="text-primary">{data.client?.phoneNumber}</span>
            </h6>
            <h6 className="flex gap-4 items-center">
              Email:{" "}
              <span className="text-primary">{data.client?.emailAddress}</span>
            </h6>
            <h6 className="flex gap-4 items-center">
              Address:{" "}
              <span className="text-primary">{data.client?.address}</span>
            </h6>

            <Link to={`${Routes.ConsultClient.pathRoute}/${data.client?.userId}`}>
            <Button type="primary" className="w-max">See more info</Button>
          </Link>

          </div>
        </div>

        <div className="bg-white">
          <h2 className="font-bold bg-primary p-2 text-white h-10 m-0">
            Appointment Details
          </h2>
          <div className="bg-slate-200 p-2 flex justify-between items-center">
            <div className="border border-primary text-primary p-2 py-1 w-max">
              {data.category?.categoryName}
            </div>
            <div>{data.appointmentDate}</div>
          </div>
          <div className="p-4">
            <h1 className="font-sans text-xl">{data.appointmentName}</h1>
            <p>{data.appointmentDescription}</p>
          </div>
        </div>
        {/* <div className="">
          <h2 className="font-bold bg-primary p-2 text-white h-10 m-0">
            Assigned Agents
          </h2>
          <div className="">
            <Table
              columns={columns}
              loading={state.removingAgent}
              dataSource={state.agents || []}
            />
          </div>
        </div> */}

        <div />

        <div className="flex justify-start w-[500px]">
          <Timeline className="w-full text-xl" mode="left">
            {data.activities?.map((activity) => {
              return (
                <Timeline.Item
                  label={moment(new Date(activity.createdAt)).format(
                    "ddd, MM yyyy HH:MM"
                  )}
                >
                  <h6>{activity.title}</h6>
                  <p>{activity.description}</p>
                </Timeline.Item>
              );
            })}
          </Timeline>
        </div>
      </div>

      <Modal
        title="Create meeting"
        onCancel={toggleModal}
        onOk={form.submit}
        confirmLoading={state.creatingMeeting}
        visible={state.showModal}
      >
        <Form layout="vertical" form={form} onFinish={createMeeting}>
          <Form.Item label="Title" name="title">
            <InputField />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <InputField.TextArea />
          </Form.Item>
          <Form.Item label="Link" name="link">
            <InputField />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
}
