import { Dropdown} from "antd";
import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { useAuthContext } from "../../hooks/useAuth";
import Button from "../button";

function UserMenu() {
  const {
    logoutUser,
    user: { name },
  } = useAuthContext().auth;

  const shortname = name.split(" ")[0].charAt(0) + name.split(" ")[1].charAt(0);
  const menu = (
    <div className="w-52 p-2 bg-white border border-gray-200 rounded shadow-lg overflow-hidden">
      <ul className="space-y-4 mt-2 p-0 mb-0">
        <Button>Profile Settings</Button>
        <Button onClick={logoutUser} danger type="primary">
          Sign Out
        </Button>
      </ul>
    </div>
  );


  return (
    <div className="relative inline-flex">
      <Dropdown overlay={menu} trigger={["click"]}>
        <div className="w-max flex items-center cursor-pointer">
          <div className="w-12 h-12 rounded-full bg-primary text-white flex justify-center items-center">
            {shortname}
          </div>
          <div className="flex items-center truncate space-x-2">
            <span className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">
              {name}
            </span>
           <FaChevronDown className="block" />
          </div>
        </div>
      </Dropdown>
    </div>
  );
}

export default UserMenu;
