import axios from "axios";
const token = localStorage.getItem("token");
const apiClient = ({ method, url, data, params= {}}) => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  if (token) {
    const headers = { Authorization: `Bearer ${token}` };
    axios.defaults.headers = headers;
  }

  return axios({
    data,
    url,
    method,
    params
  });
};

export default apiClient;
