import { Modal, Table } from "antd";
import React, { useReducer, useEffect } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import apiClient from "../apiClient";
import AutoComplete from "../components/AutoComplete";
import Button from "../components/button";
import Layout from "../components/layout/layout";
import { useAuthContext } from "../hooks/useAuth";
import Routes from "../routes";

export default function AppointmentDetails() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});
  const params = useParams();
  const appointmentId = params.appointmentId;
  let auth = useAuthContext().auth;
  let hasAction = auth.hasAction;

  const fetchData = async () => {
    dispatch({ fetchingData: true });
    try {
      const res = await apiClient({
        method: "get",
        url: `/appointment/${appointmentId}`,
      });
      if (res.data.success) {
        const data = res.data.data;
        const agents = res.data.agents;
        let exceptIds = agents.map((user) => user.userId) || [];
        dispatch({ data, agents, exceptIds, fetchingData: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingData: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  const getAgents = async () => {
    dispatch({ fetchingAgent: true });
    try {
      const res = await apiClient({
        method: "get",
        url: `/appointment/getAgent/${params.appointmentId}`,
      });
      if (res.data.success) {
        const agents = res.data.data;
        dispatch({ agents, fetchingAgent: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingAgent: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  const getUsers = async (profileId) => {
    dispatch({ fetchingUsers: true });
    try {
      const res = await apiClient({
        method: "get",
        url: `/users`,
        params: { profile: profileId, except: state.exceptIds },
      });
      if (res.data.success) {
        const users = res.data.data.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.userId,
        }));
        dispatch({ users, fetchingUsers: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingUsers: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };
  const getProfile = async () => {
    dispatch({ fetchingProfile: true });
    try {
      const res = await apiClient({
        method: "get",
        url: "/profiles",
      });
      if (res.data.success) {
        const profiles = res.data.data.map((profile) => ({
          label: profile.name,
          value: profile.id,
        }));
        dispatch({ profiles, fetchingProfile: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ fetchingProfile: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  };

  const columns = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Profile",
      dataIndex: "profile",
      key: "profile",
      render(profile = {}) {
        let name = profile.profileName;
        return name ? (
          <div className="w-max py-1 px-2 border text-primary border-primary">
            {name}
          </div>
        ) : (
          ""
        );
      },
    },

    {
      title: "",
      dataIndex: "userId",
      key: "userId",
      render(userId) {
        return (
          <div>
            <button
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure you want to remove this user?",
                  onOk: () => {
                    removeUser(userId);
                  },
                });
              }}
              className="text-red-500"
            >
              <FaTrashAlt />
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
    getProfile();
  }, []);

  let data = state.data || {};

  function searchUser(event) {
    dispatch({ selectedProfile: event });
    if (event) {
      getUsers(event);
    }
  }

  const handleUserSelect = (user) => {
    dispatch({ user });
  };

  async function addgent() {
    dispatch({ addingAgent: true });
    try {
      let data = {
        userId: state.user,
        appointmentId,
      };
      const res = await apiClient({
        method: "put",
        url: "/appointment/addAgent",
        data,
      });
      if (res.data.success) {
        Modal.success({
          title: "User Added!",
          onOk: () => {
            fetchData();
            getUsers(state.user);
          },
        });
        dispatch({ addingAgent: false, user: null });
      } else {
        dispatch({ addingAgent: false, user: null });
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ addingAgent: false, user: null });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  }
  async function removeUser(user) {
    dispatch({ removingAgent: true });
    try {
      let data = {
        userId: user,
        appointmentId,
      };
      const res = await apiClient({
        method: "delete",
        url: "/appointment/removeAgent",
        data,
      });
      if (res.data.success) {
        Modal.success({
          title: "User Removed!",
          onOk: () => {
            fetchData();
            getUsers(state.user);
          },
        });
        dispatch({ removingAgent: false });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      dispatch({ removingAgent: false });
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  }

  async function handleVal(val) {
    let data = {
      appointmentId,
      val,
    };
    dispatch({ validating: true });
    try {
      let res = await apiClient({ method: "put", url: "/appointment", data });
      if (res.data.success) {
        fetchData();
        Modal.success({ title: "Appointment successfully validated" });
      } else {
        Modal.error({ title: "An error occured!" });
      }
    } catch (error) {
      Modal.error({ title: "An error occured!" });
      console.log(error);
    }
  }


  return (
    <Layout>
      <div className="space-y-4">
        <div className="bg-white">
            <h2 className="font-bold bg-primary p-2 text-white h-10 m-0">
              Sender Details
            </h2>
          <div className="p-4">
            <h6 className="flex gap-4 items-center">
              Name:{" "}
              <span className="text-primary">
                {data.client?.firstName} {data.client?.lastName}
              </span>
            </h6>
            <h6 className="flex gap-4 items-center">
              Phone:{" "}
              <span className="text-primary">{data.client?.phoneNumber}</span>
            </h6>
            <h6 className="flex gap-4 items-center">
              Email:{" "}
              <span className="text-primary">{data.client?.emailAddress}</span>
            </h6>
            <h6 className="flex gap-4 items-center">
              Address:{" "}
              <span className="text-primary">{data.client?.address}</span>
            </h6>

            <Link to={`${Routes.ConsultClient.pathRoute}/${data.client?.userId}`}>
            <Button type="primary" className="w-max">See more info</Button>
          </Link>


          </div>
        </div>

        <div className="bg-white">
          <h2 className="font-bold bg-primary p-2 text-white h-10 m-0">
            Appointment Details
          </h2>
          <div className="bg-slate-200 p-2 flex justify-between items-center">
            <div className="border border-primary  text-primary p-2 py-1 w-max">
              {data.category?.categoryName}
            </div>
            <div>{data.appointmentDate}</div>
          </div>
          <div className="p-4">
            <h1 className="font-sans text-xl">{data.appointmentName}</h1>
            <p>{data.appointmentDescription}</p>
          </div>
        </div>
        <div className="">
          <h2 className="font-bold bg-primary p-2 text-white h-10 m-0">
            Assigned Agents
          </h2>
          <div className="">
            <div className="p-2 bg-white flex flex-wrap gap-2 items-end">
              <div>
                <h4>Select Profile</h4>
                <AutoComplete
                  loading={state.fetchingUsers}
                  onChange={searchUser}
                  value={state.selectedProfile}
                  options={state.profiles}
                />
              </div>
              <div>
                <h4>Select User</h4>
                <AutoComplete
                  disabled={!state.users}
                  onChange={handleUserSelect}
                  value={state.user}
                  options={state.users}
                />
              </div>

              <Button
                className="w-max"
                disabled={!state.user}
                onClick={addgent}
                type="primary"
              >
                Add User
              </Button>
            </div>
            <Table
              columns={columns}
              loading={state.removingAgent}
              dataSource={state.agents || []}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
