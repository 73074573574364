import { Modal } from "antd";
import React, { useReducer, useEffect } from "react";
import apiClient from "../apiClient";

import Layout from "../components/layout/layout";
import Table from "../components/table";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Routes from "../routes";

export default function Clients() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});

  const columns = [
    {
      title: "First name ",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (v) => {
        console.log(v);
        return (
          <Link to={`${Routes.ConsultClient.pathRoute}/${v.userId}`}>
            <a>
              <FaSearch />
            </a>
          </Link>
        );
      },
    },
  ];

  const fetchData = async () => {
    const res = await apiClient({ method: "get", url: "/clients" });
    if (res.data.success) {
      const data = res.data.data.map((val) => ({ ...val, action: val }));
      dispatch({ data });
    } else {
      Modal.error({ title: "Error", content: <p>An error occured!</p> });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <div>
        <Table
          columns={columns}
          dataSource={state.data || []}
          title="Clients"
        />
      </div>
    </Layout>
  );
}
