import { Form, Modal } from "antd";
import { useState, useEffect, useReducer } from "react";
import apiClient from "../apiClient";
import Button from "../components/button";
import InputField from "../components/input_field";
import Layout from "../components/layout/layout";

const ConfigPage = () => {
  const [config, setConfig] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});

  function save(data) {
    setLoading(true);
    apiClient({
      method: "post",
      url: "/config",
      data: { ...data, moreInfo: JSON.stringify(data.moreInfo ?? []) },
    })
      .then(() => {
        Modal.success({ title: "Saved" });
        setLoading(false);
      })
      .catch(() => {
        Modal.error({ title: "Error occured" });
        setLoading(false);
      });
  }

  function fetchConfig() {
    apiClient({ method: "get", url: "/config" })
      .then((res) => {
        if (res.data.success) {
          const conf = res.data.data;
          let moreInfo = JSON.parse(conf.moreInfo ?? '[]');
        
          let c = {...conf, moreInfo};
          dispatch({ moreInfo });
          setConfig({ ...c });
        }
      })
      .catch(() => {
        Modal.error({
          title: "Error occured",
          content: <p>Error occured fetching config.</p>,
        });
      });
  }

  useEffect(() => {
    fetchConfig();
  }, []);

  const addField = () => {
    let fields = state.moreInfo ?? [];
    fields.push("");
    dispatch({ moreInfo: fields });
  };

  const deleteField = index => {
    let fields = form.getFieldValue("moreInfo") ?? [];
    let moreInfo = fields.filter((_, i) => i !== index);
    form.setFieldsValue({moreInfo})
    dispatch({ moreInfo });
  }


  return (
    <Layout>
      <div className="w-full max-w-3xl mx-auto">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl">Config</h1>
          <Button
            type="primary"
            className="w-max"
            onClick={form.submit}
            loading={loading}
          >
            Save
          </Button>
        </div>
        {config && (
          <Form
            layout="vertical"
            form={form}
            onFinish={save}
            initialValues={config}
          >
            <Form.Item label="Admin email" name="admin_email">
              <InputField />
            </Form.Item>

            <h1 className="text-2xl">Additional Info</h1>

            {state.moreInfo?.map((info, i) => {
              return (
                <>
                  <Form.Item
                    label={`${i + 1}. What do you want to ask for?`}
                    name={["moreInfo", i]}
                    className="!mb-0"
                  >
                    <InputField  />
                  </Form.Item>
                  <div className="justify-end flex mb-4">
                  <Button type="text" danger className="w-max !p-0 !h-max" onClick={() => deleteField(i)}> Delete</Button>
                    </div>
                </>
              );
            })}

            <Button type="primary" onClick={addField}>
              Add
            </Button>
          </Form>
        )}
      </div>
    </Layout>
  );
};

export default ConfigPage;
