import { Input as AntInput, AutoComplete as AntAutoComplete } from "antd";
import { useState } from "react";

export default function AutoComplete({
  options = [],
  value: initValue,
  onChange = () => {},
  ...rest
}) {
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = useState(initValue);

  let dataSource = options.map((option) => {
    const { label, value: optionValue } = option;
    return {
      label,
      value: optionValue,
      key: label,
    };
  });

  function handleChange(value) {
    setValue(value)
    onChange(value);
  }
  return (
    <AntAutoComplete
      {...rest}
      onChange={handleChange}
      allowClear
      searchValue={searchValue}
      onSearch={setSearchValue}
      value={dataSource.find((option) => option.value === value)?.label}
      options={dataSource}
      filterOption={(inputValue, option) => {
        return (
          option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        );
      }}
    >
      <AntInput placeholder="Search or select a client" size="large" />
    </AntAutoComplete>
  );
}
