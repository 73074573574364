import { Form } from "antd";
import Button from "../../components/button";
import InputField from "../../components/input_field";
import { useAuthContext } from "../../hooks/useAuth";

export default function Login() {
  const {loginUser, loading} = useAuthContext().auth;
  return (
    <div className="flex justify-center items-center min-h-screen p-10 overflow-auto bg-blue-900 flex-col gap-4">
      <h1 className="text-4xl text-white">GIP Admin</h1>
      <div className="w-full max-w-xl  bg-white shadow h-max rounded-md p-4 flex flex-wrap">
        <div className="py-10 p-4 font-semibold text-lg flex-1">
          <h3 className="font-bold text-3xl text-center">Login</h3>
          <div className="w-full h-full">
            <Form layout="vertical" onFinish={loginUser}>
                <Form.Item label="Email" name="emailAddress" rules={
                  [
                    {required: true, message: "This field is required"},
                    {type: "email", message: "Enter a correct email address"}
                  ]
                }>
                  <InputField
                    placeholder="Type your first name"
                  />
                </Form.Item>
                <Form.Item label="Password" name="password" rules={
                  [
                    {required: true, message: "This field is required"},
                    {min: 3, message: "Password must be 6 characters long"}
                  ]
                }>
                  <InputField.Password
                    placeholder="Type your first name"
                  />
                </Form.Item>
                <Form.Item >
                <Button type="primary" className="w-full" htmlType="submit" loading={loading}>Login</Button>
                </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
